<template>
  <div class="card card-no-border card-first">
    <h3 :class="hideProcess ? 'border' : 'title'">
      The funding process <i v-if="isMobile" @click="hideProcessSection()" :class="[{ rotate: hideProcess }, 'material-icons']">expand_more</i>
    </h3>
    <div :class="hideProcess ? '' : 'process-container'">
      <div v-for="(process, index) in setProcessSection" :key="process.title" :class="[{ 'hide-process': hideProcess }]">
        <div v-if="!isMobile" class="description">{{ (currentFundingProcessIndex === index && process.description) || '' }}</div>
        <div class="process-wrapper">
          <div class="icon-card flex" :class="{ activeSection: currentFundingProcessIndex === index, progress: process.displayGreen}" @click="handleFundingProcess(index)">
            <span>{{ process.title }}</span>
            <i class="material-icons icon" v-if="currentFundingProcessIndex === index || process.displayGreen">check_circle</i>
            <i class="material-icons icon" v-else>check_circle_outline</i>
          </div>
          <div class="vertical-line" v-if="index !== setProcessSection.length - 1" />
        </div>
        <div v-if="isMobile" class="description-mobile">{{ (currentFundingProcessIndex === index && process.description) || '' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FundingProcessCard',
  props: {
    fundingProcessDetails: {
      type: Array,
      default: () => []
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    currentFundingProcessIndex: {
      type: Number,
      default: 0
    },
    hideProcess: {
      type: Boolean,
      default: false
    },
    handleFundingProcess: {
      type: Function,
      default: () => {}
    },
    hideProcessSection: {
      type: Function,
      default: () => {}
    },
    productStatus: {
      type: String
    }
  },
  computed: {
    setProcessSection() {
      const foundIndex = this.fundingProcessDetails.findIndex(process => process.status === this.productStatus)
      this.fundingProcessDetails.map((item, index) => {
        if (index <= foundIndex) {
          item.displayGreen = true
        } else {
          item.displayGreen = false
        }
        return item
      })
      return [...this.fundingProcessDetails]
    }
  }
}
</script>
