<template>
  <div>
    <!-- loading -->
    <div class="opportunity-screen">
      <div v-if="!opportunity" class="ui active inverted dimmer page-loader">
        <div class="ui medium text loader"><spinner :size="64" :loader="true" color /></div>
      </div>
      <!-- render component -->
      <div v-else>
        <header class="opportunity-header">
          <BackBtn />
          <span v-if="opportunity">{{ opportunity.type }} funding</span>
          <span />
        </header>

        <aside class="container">
          <div class="opportunity-wrapper">
            <!-- NEW Loan product details section -->
            <div v-if="opportunity && (opportunity.type === 'Loan' || opportunity.type === 'LiveOffer')">
              <h3 class="header">
                <div>
                  <div class="opportunity-type">{{ opportunity.productName }}</div>
                  <div class="opportunity-subcategory">
                    {{ subcategoryName }}
                  </div>
                </div>
                <div v-if="!isMobile">
                  <button v-if="opportunityStages && !opportunityStages.isStartApp" class="btn btn-sm btn-green" type="button">
                    Interest registered <i class="material-icons">check</i>
                  </button>
                  <button v-else class="btn btn-sm btn-primary" type="button" v-promise-btn @click="startApplicationClickHandler" data-testid="register-interest-button">
                    Register your interest
                  </button>
                </div>
              </h3>
              <LoanOpportunityTile
                  :opportunity="opportunity"
                  :opportunitySpeedDic="opportunitySpeedDic"
                  :logoUri="logoUri"
                  :loanTermMaxDic="loanTermMaxDic"
                />
              <div class="information">
                <div class="upper-side">
                  <h3 :class="hideProductInfo ? 'border' : 'title'">
                    Product information <i v-if="isMobile" @click="hideProductInfoSection()" :class="[{ rotate: hideProductInfo }, 'material-icons']">expand_more</i>
                  </h3>
                  <NeedHelp v-if="!isMobile" :contactInformation="contactInformation" />
                </div>
                <section v-if="opportunity.detailedDescription && hideProductInfo && isMobile" v-html="opportunity.detailedDescription" />
                <section v-else-if="opportunity.detailedDescription && !isMobile" v-html="opportunity.detailedDescription" />
              </div>

              <div class="cards-container">
                <!-- Funding Process Card -->
                <FundingProcessCard
                  :fundingProcessDetails="fundingProcessDetails"
                  :isMobile="isMobile"
                  :currentFundingProcessIndex="currentFundingProcessIndex"
                  :hideProcess="hideProcess"
                  :handleFundingProcess="handleFundingProcess"
                  :hideProcessSection="hideProcessSection"
                  :productStatus="productStatus"
                />

                <!-- Documents Card -->
                <div>
                  <!-- LIVEOFFERS check eligibility loading screen -->
                  <template v-if="currentStage === null && !isCheckingEligibility">
                    <div class="ui active inverted dimmer page-loader">
                      <div class="ui medium text loader">One moment</div>
                    </div>
                  </template>

                  <!-- LOAN zero state -->
                  <template v-if="opportunityStages.isStartApp">
                    <div class="card locked">
                      <div class="opacity">
                        <h3><i class="material-icons secondary-color">fast_forward</i><span class="text">Get quicker funding</span></h3>
                        <p>You can speed up your funding process by sharing these documents with us:</p>
                      </div>
                      <div class="title">Does this look like the right product for you?</div>
                      <div class="text">
                        To access more detailed information and to upload the necessary documents we need for this product, click
                        <span class="bold">"Register your interest"</span> - it lets us know you’re interested.
                      </div>
                      <div class="text">If you have any questions, please contact us.</div>
                      <button class="btn btn-sm btn-primary" type="button" v-promise-btn @click="startApplicationClickHandler">Register your interest</button>
                    </div>
                  </template>

                  <!-- LOAN after request documents screen -->
                  <template v-if="opportunityStages.isBookmarkedApp">
                    <div class="card card-border">
                      <h3 class="title">
                        <i class="material-icons secondary-color">fast_forward</i><span class="text">Get quicker funding</span>
                        <popover placement="top" trigger="hover click" class="popover">
                          <i class="material-icons main-color">help</i>
                          <template #popover>
                            When you share your documents with us, you can rest assured your data is safe and secure. Swoop is regulated by the FCA (Financial Conduct Authority)
                            and you can see exactly how we handle your information in our privacy policy.
                          </template>
                        </popover>
                      </h3>
                      <div>
                        <p v-if="isCashFlowForecast">
                          Futrli can provide you with a 3 year forecast Profit &amp; Loss, Balance Sheet and Cash Flow statement for all your Covid-19 funding applications
                          <a :href="futrliUrl" target="_blank" rel="noopener">here</a>
                        </p>
                        <p v-if="opportunity.requiredDocuments.hasSalie">
                          To help you generate your Statement of Assets, Liabilities, Income and Expenditure (SALIE) document we have generated a template, you can download this
                          <a href="/files/Swoop-SALIE-Template.pdf" download>here</a>.
                        </p>
                      </div>
                      <div class="documents-wrapper">
                        <p>You can speed up your funding process by sharing these documents with us:</p>
                        <RequiredDocuments
                          class="documents-container"
                          v-bind="{ documentsList, requiredDocumentsList }"
                          @change="documentsListChangeHandler"
                          @click:checkbox="requiredDocumentsListCheckboxClickHandler"
                        />
                        <div class="button-container">
                          <button v-if="!dontDisplayModal" class="btn btn-sm btn-white" type="button" @click="onDontHaveDocsBtnClick">I don't have these documents</button>
                          <button class="btn btn-sm btn-primary" type="button" v-promise-btn @click="processedToApplicationClickHandler" :disabled="!hasAllRequiredDocuments">
                            Send documents
                          </button>
                        </div>
                      </div>
                    </div>
                  </template>

                  <!-- LOAN after submitting document the application review by swoop screen -->
                  <template v-if="opportunityStages.isUnderReview">
                    <div class="card card-border">
                      <h3 class="title">
                        <i class="material-icons secondary-color">fast_forward</i><span class="text">Get quicker funding</span>
                        <popover placement="top" trigger="hover click" class="popover">
                          <i class="material-icons main-color">help</i>
                          <template #popover>
                            When you share your documents with us, you can rest assured your data is safe and secure. Swoop is regulated by the FCA (Financial Conduct Authority)
                            and you can see exactly how we handle your information in our privacy policy.
                          </template>
                        </popover>
                      </h3>
                      <div class="documents-wrapper">
                        <p>You can speed up your funding process by sharing these documents with us:</p>
                        <div class="inner-card">
                          <div class="bold">Thank you</div>
                          <div class="inner-card-details">
                            <div v-if="['Barclays', 'RBS', 'NatWest', 'Ulster Bank'].includes(opportunity.providerName)">
                              <div v-if="opportunity.providerName === 'Barclays'">
                                <div>
                                  You’ve been given an indicative offer of <span class="bold">a business loan up to a maximum of <currency-symbol />25,000</span> with Barclay’s
                                  Bank UK PLC.
                                </div>
                                <span>This offer is subject to:</span>
                                <ul>
                                  <li>your application your financial circumstances</li>
                                  <li>your borrowing history</li>
                                  <li>Barclay’s assessment of your business</li>
                                </ul>
                                <div>
                                  When you select the ‘Submit’ button, your details will be sent to Barclay’s who will contact you within 24 hours to discuss your application.
                                </div>
                                <div>Terms, conditions and exclusions apply.</div>
                              </div>
                              <div v-else>
                                Your details have been submitted to {{ opportunity.providerName }}, <br />
                                who will be in contact with you to <br />
                                discuss the next steps of the application <br />
                                (Application ID {{ currentApplicationId }}).
                              </div>
                            </div>
                            <div v-else>
                              <h3 class="h3 ui text dark">Your application is under review!</h3>
                              <p>An email has been sent to confirm that your application has been sent and is now with the <tenant-text name="projectName" /> team for review.</p>
                              <p>In the meantime, if you have any questions please feel free to contact our friendly team.</p>
                            </div>
                          </div>

                          <router-link class="btn btn-sm btn-primary" :to="{ name: viewApplicationsRouteName }">View applications</router-link>
                        </div>
                      </div>
                    </div>
                  </template>

                  <!-- LIVEOFFERS zero state -->
                  <template v-if="opportunityStages.isStartCompanyOwners">
                    <div class="card card-border">
                      <h3 class="title" v-if="!isPassFinancialInformation || !isPassCompanyOwners">Looks like you need a few more details</h3>
                      <h3 class="title" v-else>Check eligibility</h3>
                      <p>This application requires details on your company owners to be up-to-date and submitted truthfully</p>
                      <div class="eligibility">
                        <ul>
                          <li>
                            <router-link @click.native="trackLiveOfferFillInfo('Financial Information')" :to="{ name: 'financial-information' }">
                              <span>Financial Information</span>
                              <span :class="isPassFinancialInformation ? 'icon-status-checked' : 'icon-status-warning'"></span>
                            </router-link>
                          </li>
                          <li v-if="opportunity.providerName === 'Barclays' && opportunity.subcategory === 'TermLoan'">
                            <router-link @click.native="trackLiveOfferFillInfo('Additional Financial Information')" :to="{ name: 'additional-financial-information' }">
                              <span>Additional Financial Information</span>
                              <span :class="isPassAdditionalFinancialInformation ? 'icon-status-checked' : 'icon-status-warning'"></span>
                            </router-link>
                          </li>
                        </ul>
                      </div>
                      <button class="btn btn-sm btn-primary" type="button" v-promise-btn :disabled="!isLiveOfferEligibilityQuestionFinished" @click.prevent="checkEligibility">
                        Check eligibility
                      </button>
                    </div>
                  </template>

                  <!-- LIVEOFFERS in progress -->
                  <template v-if="opportunityStages.isCheckingEligibility">
                    <div class="opportunity-details-action card row m-b-50">
                      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 ui flex center eligible-checker">
                        <div class="application-logo">
                          <spinner :size="133" :loader="true" color />
                        </div>
                        <div>
                          <span class="eligible-check">Checking eligibility...</span>
                        </div>
                      </div>
                    </div>
                  </template>

                  <!-- LIVEOFFERS When Eligibility finished and it is NOT Eligible -->
                  <template v-if="opportunityStages.isNotEligible">
                    <div class="card card-border">
                      <p>Sorry, you are currently not eligible for this facility.</p>
                      <p>To view other funding options, please click below.</p>
                      <p>
                        <router-link class="ui button primary business-gradient circular" :to="{ name: viewApplicationsRouteName }" href> Explore funding options </router-link>
                      </p>
                    </div>
                  </template>

                  <!-- LIVEOFFERS When Eligibility finished and it is Eligible -->
                  <template v-if="opportunityStages.isSubmitEligible">
                    <div class="card">
                      <div v-if="opportunity.providerName === 'Barclays'">
                        <h3>Thank you</h3>
                        <p>
                          The indicative offer you have been provided is the likelihood of acceptance of a business loan up to a maximum of <currency-symbol />25,000 with Barclays
                          Bank UK PLC ('Barclays').
                        </p>
                        <p>The indicative offer is subject to applications, financial circumstances, borrowing history and Barclays' lending assessment of your business.</p>
                        <p>By selecting the 'Submit' button below your details will be sent to Barclays who will contact you within 24 hours to discuss starting an application.</p>
                        <p>Terms, condition, exclusions apply.</p>
                        <button class="btn btn-sm btn-primary" type="button" v-promise-btn @click="submitForSwoopReviewClickHandler">
                          Submit to {{ opportunity.providerName }}
                        </button>
                      </div>
                      <div v-else>
                        <h3>Congratulations 🎉</h3>
                        <p>You're pre-approved for your funding requirement of {{ currentTotalFundingRequired | currency }} based on the information that you have provided.</p>
                        <p>Approval for lending and pricing details will be confirmed following a successful application (Application ID {{ currentApplicationId }}).</p>

                        <div class="profile-info">
                          <div class="profile-logo">
                            <img class="ui small centered image" :src="logoUri" :alt="opportunity.type + ' logo'" v-if="opportunity && opportunity.logoUri" />
                          </div>
                          <button class="btn btn-sm btn-primary" type="button" v-promise-btn @click="submitForSwoopReviewClickHandler">
                            Submit to {{ opportunity.providerName }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </template>

                  <template v-if="opportunityStages.isProductWithoutSubmitFlow">
                    <div class="card card-border">
                      <div class="padding">
                        <h3 class="h3 ui text dark">Continue to our partner's site to apply!</h3>
                        <p>The process is simple, by clicking the button below you'll be taken straight to our partner's site to apply for your desired product.</p>
                        <a
                          class="btn btn-primary"
                          :href="opportunity.productWithoutSubmitFlowLink | http"
                          target="_blank"
                          rel="noopener"
                          @click.prevent="readOnlyProductContinueToClickHandler"
                          >Continue to {{ opportunity.providerName }}</a
                        >
                      </div>
                      <div class="profile-info">
                        <div class="profile-logo">
                          <img class="ui small centered image" :src="logoUri" :alt="opportunity.type + ' logo'" v-if="opportunity && opportunity.logoUri" />
                        </div>
                      </div>
                    </div>
                  </template>

                  <!-- Your data is safe with Swoop card -->
                  <section v-if="!opportunityStages.isStartApp">
                    <h4><i class="material-icons icon">lock</i>Your data is safe with Swoop</h4>
                    <div>We’re regulated by the Financial Conduct Authority (FCA) and have strong security measures in place to protect your personal and company data.</div>
                  </section>
                </div>
              </div>

              <div v-if="fundingAmountValue >= 10000">
                <MatchingPageBanner />
              </div>
            </div>

            <HaveNoDocumentsModal :dismiss="dismiss" />

            <!-- Grant Start Screen -->
            <div v-if="opportunity && opportunity.type === 'Grant'">
              <GrantOpportunityTile
                :opportunity="opportunity"
                :displayedOpportunityType="displayedOpportunityType"
                :currentOpportunitySubCategory="currentOpportunitySubCategory"
                :contactInformation="contactInformation"
                :logoUri="logoUri"
              />
              <!-- Grant Start Screen -->
              <template v-if="opportunityStages.isStartApp">
                <div class="opportunity-details-action card m-b-50">
                  <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                      <div class="box">
                        <h3 class="h3 ui text dark"><tenant-text name="swoopReviewProcess" /></h3>
                        <p>By providing your requirements and relevant documentation, we can review your application.</p>
                        <p>You shall be sent a confirmation email once you have submitted your application to us for next steps.</p>
                        <p>Your credit rating will not be affected by proceeding with your <tenant-text name="projectName" /> review process.</p>
                      </div>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 ui flex column center action-center">
                      <div class="box action-container">
                        <button
                          v-if="opportunity && opportunity.type === 'Grant'"
                          class="ui button primary circular relative btn-product-action m-b-10"
                          type="button"
                          v-promise-btn
                          @click="processedToApplicationClickHandler"
                        >
                          <tenant-text name="submitApplication" />
                        </button>
                        <button v-else class="btn btn-sm btn-primary btn-product-action m-b-10" type="button" v-promise-btn @click="startApplicationClickHandler">
                          <tenant-text name="startSwoopReview" />
                        </button>
                        <div class="text-danger m-b-15" v-if="errorResponse && errorResponse.data">{{ errorResponse.data.message }}</div>
                      </div>
                      <div class="box action-container">
                        <button class="btn btn-sm btn-product-action btn-secondary" type="button" v-promise-btn @click="requestCallbackClickHandler">Request Callback</button>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template v-if="opportunityStages.isBusinessPlan">
                <div class="opportunity-details-action card m-b-50">
                  <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                      <div class="box">
                        <h3 class="h3 ui text dark">Innovation Grants</h3>
                        <p>
                          Commercial grant funding is highly competitive and these applications are a big commitment. We have partners who are experts in the field and can support
                          you in every stage of your grant application, helping you increase your chances of success.
                        </p>
                        <button class="btn btn-sm btn-primary" :disabled="!innovationGrantEnabled" @click.prevent="innovationGrantInterestRegistration">
                          Register your interest for this grant
                        </button>
                      </div>
                    </div>
                    <div class="col-xs col-sm-6 col-md-6 col-lg-6">
                      <div class="profile-info">
                        <div class="profile-logo">
                          <img class="ui small centered image" :src="logoUri" :alt="opportunity.type + ' logo'" v-if="opportunity && opportunity.logoUri" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p class="disclaimer">
                    Please note, this is not an application. Given the level of enquiries we receive we will only be in contact with you if we feel you meet the requirements for a
                    particular innovation grant and we can support you with your application. You can apply directly to any grants that you are matched to from the Swoop Grant
                    Finder tool.
                  </p>
                </div>
              </template>

              <template v-if="opportunityStages.isProductWithoutSubmitFlow">
                <div class="opportunity-details-action card m-b-50">
                  <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                      <div class="box">
                        <h3 class="h3 ui text dark">Continue to our partner's site to apply!</h3>
                        <p>The process is simple, by clicking the button below you'll be taken straight to our partner's site to apply for your desired product.</p>
                        <a
                          class="btn btn-sm btn-primary"
                          :href="opportunity.productWithoutSubmitFlowLink | http"
                          target="_blank"
                          rel="noopener"
                          @click.prevent="readOnlyProductContinueToClickHandler"
                          >Continue to {{ opportunity.providerName }}</a
                        >
                      </div>
                    </div>
                    <div class="col-xs col-sm-6 col-md-6 col-lg-6">
                      <div class="profile-info">
                        <div class="profile-logo">
                          <img class="ui small centered image" :src="logoUri" :alt="opportunity.type + ' logo'" v-if="opportunity && opportunity.logoUri" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template v-if="opportunityStages.isUnderReview">
                <div class="opportunity-details-action card m-b-50">
                  <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                      <div class="box" v-if="['Barclays', 'RBS', 'NatWest', 'Ulster Bank'].includes(opportunity.providerName)">
                        <h3 class="h3 ui text dark">Enquiry Submitted to {{ opportunity.providerName }}!</h3>
                        <p v-if="opportunity.providerName === 'Barclays'">
                          Your details have been submitted to Barclays, <br />
                          who will contact you within 24 hours to <br />
                          discuss starting an application (reference number {{ opportunity.quoteReferenceId }})
                        </p>
                        <p v-else>
                          Your details have been submitted to {{ opportunity.providerName }}, <br />
                          who will be in contact with you to <br />
                          discuss the next steps of the application <br />
                          (Application ID {{ currentApplicationId }}).
                        </p>
                        <div class="ui flex center">
                          <router-link class="btn btn-sm btn-primary" :to="{ name: viewApplicationsRouteName }">View applications</router-link>
                        </div>
                      </div>
                      <div class="box" v-else>
                        <h3 class="h3 ui text dark">Your application is under review!</h3>
                        <p>An email has been sent to confirm that your application has been sent and is now with the <tenant-text name="projectName" /> team for review.</p>
                        <p>In the meantime, if you have any questions please feel free to contact our friendly team.</p>
                      </div>
                    </div>
                    <div class="col-xs col-sm-6 col-md-6 col-lg-6" v-if="['Barclays', 'RBS', 'NatWest', 'Ulster Bank'].includes(opportunity.providerName)">
                      <div class="profile-info">
                        <div class="profile-logo">
                          <img class="ui small centered image" :src="logoUri" :alt="opportunity.type + ' logo'" v-if="opportunity && opportunity.logoUri" />
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 view-applications" v-else>
                      <div class="application-logo">
                        <img class src="@/assets/images/application-under-review.svg" alt="application under review" />
                      </div>
                      <div class="ui flex align center">
                        <router-link class="btn btn-sm btn-primary" :to="{ name: viewApplicationsRouteName }">View applications</router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>

            <main class="opportunity-details-info" v-if="opportunity && opportunity.type === 'Grant'">
              <section class="box">
                <h3 class="title bb">Product information</h3>
                <section v-if="opportunity.detailedDescription" v-html="opportunity.detailedDescription"></section>
              </section>
              <em class="card" v-if="isLiveOffers">Your credit rating will not be effected by proceeding.</em>
            </main>
          </div>
        </aside>
      </div>
      <div v-if="isMobile && opportunity && opportunity.type !== 'Grant'">
        <div class="fixed-container">
          <button v-if="!opportunityStages.isStartApp" class="btn btn-sm btn-green">Interest registered <i class="material-icons">check</i></button>
          <button v-else class="btn btn-sm btn-primary" type="button" v-promise-btn @click="startApplicationClickHandler">Register your interest</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { mapGetters } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import { fundingGetters } from '@/store/routines'
import { getOpportunity, opportunityCallback, registerInterest, getProductStatus, getRecommendationsList } from '@product/api/opportunity'
import { getOpportunityApplication, setOpportunityApplication, setOpportunityApplicationInReview } from '@/api/opportunityApplication'
import { getCompany, getCompanyOwners } from '@/api/company'
import { getFinancialData, getAdditionalFinancialData } from '@/api/financialData'
import {
  postRBSSoftSearch,
  getRBSSoftSearch,
  postHSBCSoftSearch,
  getHSBCSoftSearch,
  postBarclaysSoftSearch,
  getBarclaysSoftSearch,
  submitBarclaysSoftSearch,
  submitRBSSoftSearch
} from '@/api/eligibility'
import { getDocumentsList } from '@/api/document'
import { getWhitelabelNameFromSubdomain, makeRequestTillPassCondition, requiredDocumentsAdapter } from '@/utils/utils'
import {
  loanTermMaxDic,
  eligibleTermLoanHsbcAppUrl,
  eligibleOverdraftsHsbcAppUrl,
  amplitudeEvents,
  opportunitySpeedDic,
  loanSubCategoryTypeOptions,
  fundingProcessDetails
} from '@/utils/constants'
import { getFundingApplication } from '@/api/fundingApplication'
import { registerInterestForInnovationGrant } from '@/api/slackNotifications'
import { notificationRoutine } from '@/store/modules/notification/routines'
import { toastDefaultOptions } from '@/config/vue-toast'
import RequiredDocuments from '@/components/RequiredDocuments'
import BackBtn from '@/components/BackBtn'
import NeedHelp from '@/components/NeedHelp'
import Spinner from '@/components/Spinner'
import GrantOpportunityTile from '@/components/opportunity/GrantOpportunityTile'
import LoanOpportunityTile from '@/components/opportunity/LoanOpportunityTile'
import FundingProcessCard from '@/components/opportunity/FundingProcessCard'
import HaveNoDocumentsModal from './opportunity/haveNoDocumentsModal'
import MatchingPageBanner from '@/components/MatchingPageBanner'
import { fundingAmountGetters, currentFormNameGetters } from '@/store/modules/currentFormDetails/routines'
import CurrencySymbol from '@/components/CurrencySymbol'
import { sortBarGetters } from '@/store/modules/sortBar/routines'

export default {
  name: 'OpportunityView',
  components: {
    RequiredDocuments,
    FundingProcessCard,
    NeedHelp,
    BackBtn,
    Spinner,
    LoanOpportunityTile,
    GrantOpportunityTile,
    HaveNoDocumentsModal,
    MatchingPageBanner,
    CurrencySymbol
  },
  data() {
    return {
      amplitudeEvents,
      loanTermMaxDic,
      opportunitySpeedDic,
      loanSubCategoryTypeOptions,
      equityRequiredDocuments: {
        hasPitchDeck: true
      },
      subcategoryName: null,
      opportunityStages: {
        isProductWithoutSubmitFlow: false,
        isRequestApp: false,
        isStartApp: false,
        isBookmarkedApp: false,
        isPitchDeck: false,
        isBusinessPlan: false,
        isStartCompanyOwners: false,
        isCheckingEligibility: false,
        isSubmitEligible: false,
        isNotEligible: false,
        isUnderReview: false
      },
      dismiss: false,
      futrliUrl: process.env.VUE_APP_FUTRLI_URL,
      opportunity: null,
      company: null,
      documentsListFromServer: null,
      documentsListCheckbox: [],
      companyOwners: null,
      financialInformation: null,
      additionalFinancialInformation: null,
      eligibleStatus: null,
      errorResponse: null,
      appRedirectErrorResponse: null,
      rbsEligibilityEnabled: process.env.VUE_APP_RBS_ELIGIBILITY_ENABLED,
      correlationId: null,
      currentTotalFundingRequired: null,
      currentApplicationId: null,
      innovationGrantEnabled: true,
      contactInformation: null,
      registerInterest: false,
      currentFundingProcessIndex: 0,
      hideProductInfo: false,
      hideProcess: false,
      fundingProcessDetails,
      productStatus: null
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID,
      fundingApplicationId: fundingGetters.FUNDING_APP_ID,
      fundingAmountValue: fundingAmountGetters.FUNDING_AMOUNT,
      currentFormName: currentFormNameGetters.CURRENT_FORM_NAME,
      sortByAmount: sortBarGetters.SORT_BY_AMOUNT,
      sortByInterestRate: sortBarGetters.SORT_BY_INTEREST_RATE,
      sortByDecisionTime: sortBarGetters.SORT_BY_DECISION_TIME,
      sortByProductType: sortBarGetters.SORT_BY_PRODUCT_TYPE
    }),
    dontDisplayModal() {
      return localStorage.getItem('dontShowModal')
    },
    isMobile() {
      return window.innerWidth <= 800
    },
    viewApplicationsRouteName() {
      if (this.opportunity && ['Grant'].includes(this.opportunity?.type)) {
        return 'grant-matches'
      } else {
        return 'loan-matches'
      }
    },
    currentStage() {
      const stages = Object.entries(this.opportunityStages)
      const [currentStageName = null] = stages.find(([stageName, isActive]) => isActive) || []
      return currentStageName
    },
    isLiveOffers() {
      if (this.opportunity.isInstantEligibilityChecker === false) return false
      return (
        (this.opportunity.providerName === 'RBS' && this.opportunity.subcategory === 'TermLoan') ||
        (this.opportunity.providerName === 'HSBC' && this.opportunity.subcategory === 'TermLoan') ||
        (this.opportunity.providerName === 'HSBC' && this.opportunity.subcategory === 'Overdrafts') ||
        (this.opportunity.providerName === 'Barclays' && this.opportunity.subcategory === 'TermLoan') ||
        (this.opportunity.providerName === 'NatWest' && this.opportunity.subcategory === 'TermLoan') ||
        (this.opportunity.providerName === 'Ulster Bank' && this.opportunity.subcategory === 'TermLoan')
      )
    },
    currentOpportunitySubCategory() { // why do we need this?
      const category = loanSubCategoryTypeOptions.filter(obj => obj.value === this.opportunity.subcategory)
      return category.length ? category[0].label : ''
    },
    opportunityId() {
      return this.$route.params.opportunityId || this.opportunity.opportunityId || null
    },
    opportunityApplicationId() {
      return this.$route.params.opportunityApplicationId
    },
    isPrivateLimitedCompany() {
      return this.company?.companyStructures === 'Private Limited Company'
    },
    isPassCompanyOwners() {
      if (!this.companyOwners || !this.companyOwners.length) return false
      const allCompanyOwnersAnswers = this.companyOwners.reduce((acc, companyOwner) => {
        if (!this.$config.whitelabel.forms.states) {
          delete companyOwner.state
        }
        return [...acc, ...Object.values(companyOwner)]
      }, [])
      const isPassCompanyOwners = !allCompanyOwnersAnswers.includes(null)
      return isPassCompanyOwners
    },
    isPassFinancialInformation() {
      const isPassFinancialInformation = !!(
        ((this.financialInformation.isEstablishedLessOneYear && parseFloat(this.financialInformation.estimatedTurnover) > 0) ||
          (!this.financialInformation.isEstablishedLessOneYear && parseFloat(this.financialInformation.turnoverLastYear) >= 0)) &&
        parseFloat(this.financialInformation.currentCustomersNumber) >= 0 &&
        parseFloat(this.financialInformation.monthlyBusinessDebtRepayments) >= 0 &&
        parseFloat(this.financialInformation.existingBusinessOverdraft) >= 0 &&
        parseFloat(this.financialInformation.totalBusinessBorrowingRepayments) >= 0
      )
      return isPassFinancialInformation
    },
    isPassAdditionalFinancialInformation() {
      if (this.additionalFinancialInformation === null) return false
      return (
        this.additionalFinancialInformation.unpaidItems12Months &&
        this.additionalFinancialInformation.taxArreas &&
        this.additionalFinancialInformation.exesBusOverdraft &&
        this.additionalFinancialInformation.twoYearCcj &&
        this.additionalFinancialInformation.mortgageHeld &&
        this.additionalFinancialInformation.sixYearPerslDefault &&
        this.additionalFinancialInformation.missedPayments12Months &&
        this.additionalFinancialInformation.missedPayments6Months &&
        this.additionalFinancialInformation.oldestCreditAccAge &&
        this.additionalFinancialInformation.bankrupt &&
        this.additionalFinancialInformation.failedBusiness
      )
    },
    isLiveOfferEligibilityQuestionFinished() {
      let isFinished = this.isPassCompanyOwners && this.isPassFinancialInformation
      if (this.opportunity.providerName === 'Barclays' && this.opportunity.subcategory === 'TermLoan') {
        isFinished = isFinished && this.isPassAdditionalFinancialInformation
      }
      return isFinished
    },
    hasRequiredDocuments() {
      return Object.values(this.opportunity.requiredDocuments).includes(true)
    },
    documentsList() {
      if (!Array.isArray(this.documentsListFromServer)) return this.documentsListFromServer
      return [...this.documentsListFromServer, ...this.documentsListCheckbox]
    },
    requiredDocumentsList() {
      if (!this.opportunity || !this.opportunity.requiredDocuments || !this.documentsList) return []
      const { opportunity, documentsList, isPrivateLimitedCompany } = this
      return requiredDocumentsAdapter({
        opportunity,
        documentsList,
        isPrivateLimitedCompany,
        requiredDocuments: this.opportunity.requiredDocuments
      })
    },
    grantRequiredDocumentsList() {
      if (!this.documentsList) return []
      const { opportunity, documentsList } = this
      return requiredDocumentsAdapter({
        opportunity,
        documentsList,
        requiredDocuments: {
          hasBusinessPlan: true
        }
      })
    },
    requiredDocumentsLength() {
      return this.requiredDocumentsList.reduce((acc, doc) => {
        return acc + (doc.isDocumentUploaded ? 0 : 1)
      }, 0)
    },
    hasAllRequiredDocuments() {
      return this.requiredDocumentsList.reduce((acc, doc) => acc && doc.isDocumentUploaded, true)
    },
    hasAllEquityRequiredDocuments() {
      // no equity
      return this.equityRequiredDocumentsList.reduce((acc, doc) => acc && doc.isDocumentUploaded, true)
    },
    hasAllGrantRequiredDocuments() {
      return this.grantRequiredDocumentsList.reduce((acc, doc) => acc && doc.isDocumentUploaded, true)
    },
    hasRequiredCriteria() {
      return Object.values(this.opportunity.criterias).reduce((acc, criteria) => acc || (Array.isArray(criteria) ? !!criteria.length : !!criteria), false)
    },
    isEligibility() {
      return this.eligibleStatus === 'Eligible'
    },
    isCheckingEligibility() {
      return this.eligibleStatus === null
    },
    isRBSEligibilityEnabled() {
      return this.rbsEligibilityEnabled === 'true'
    },
    logoUri() {
      return `${this.opportunity.logoUri}?${String(Math.random()).slice(2)}`
    },
    isCashFlowForecast() {
      return this.opportunity.requiredDocuments.cashFlowForecast
    },
    displayedOpportunityType() {
      switch (this.opportunity?.type) {
        case 'LiveOffer':
          return 'Instant Eligibility Checker'
        default:
          return this.opportunity?.type
      }
    }
  },
  methods: {
    hideProcessSection() {
      this.hideProcess = !this.hideProcess
    },
    hideProductInfoSection() {
      this.hideProductInfo = !this.hideProductInfo
    },
    handleFundingProcess(index) {
      this.currentFundingProcessIndex = index
    },

    setOpportunityStage(nextStage) {
      if (this.currentStage && this.currentStage in this.opportunityStages) {
        this.opportunityStages[this.currentStage] = false
      }
      this.opportunityStages[nextStage] = true
    },
    getSubcategoryName() {
      getRecommendationsList(this.companyId, this.currentFormName)
        .then(({ data: { opportunities } }) => {
          this.subcategoryName = opportunities?.find(opportunity => opportunity?.opportunityId === this.opportunityId)?.subcategoryName
        })
        .catch(err => {
          console.error(err)
        })
    },
    loadOpportunity() {
      return getOpportunity(this.opportunityId).then(res => {
        this.opportunity = res.data

        this.contactInformation = res?.data.contactInformation
      })
    },
    loadOpportunityApplication() {
      const { opportunityApplicationId } = this
      return getOpportunityApplication({ opportunityApplicationId }).then(res => {
        this.opportunity = res.data
      })
    },
    loadCompany() {
      return getCompany(this.companyId).then(res => {
        this.company = res.data
      })
    },
    loadDocumentsList() {
      return getDocumentsList(this.companyId)
        .then(res => {
          this.documentsListFromServer = res?.data
        })
        .catch(e => {
          this.documentsListFromServer = []
        })
    },
    loadCompanyOwners() {
      return getCompanyOwners(this.companyId).then(res => {
        this.companyOwners = res.data
      })
    },
    loadFinancialInformation() {
      return getFinancialData(this.companyId).then(res => {
        this.financialInformation = res.data
      })
    },
    loadAdditionalFinancialInformation() {
      return getAdditionalFinancialData(this.companyId).then(res => {
        this.additionalFinancialInformation = res.data
      })
    },
    // Bookmarks product
    startApplicationClickHandler() {
      this.$ma.trackEvent(amplitudeEvents.dashboard.START_SWOOP_REVIEW)
      registerInterest({
        companyId: this.companyId,
        productId: this.opportunityId,
        useCase: this.currentFormName,
        amountSort: this.sortByAmount,
        decisionTimeSort: this.sortByDecisionTime,
        productTypeSort: this.sortByProductType
      })
        .then(() => {})
        .catch(error => {
          console.error(error)
        })

      this.trackIfLloydsEvent('StartSwoopReview')
      const { type } = this.opportunity
      if (type === 'Grant') {
        this.setOpportunityStage('isStartApp')
      } else {
        const { companyId, opportunityId, currentFormName } = this
        return setOpportunityApplication({
          companyId,
          opportunityId,
          useCase: currentFormName,
          status: 'InformationRequested'
        })
          .then(res => {
            return this.$router.replace({
              name: 'deal-details',
              params: { opportunityApplicationId: res.data.opportunitiyApplicationId }
            })
          })
          .then(res => {
            this.loadOpportunityApplication()
          })
          .then(() => {
            this.setOpportunityStage('isBookmarkedApp')
          })
          .catch(e => {
            this.toastError()
          })
      }
    },
    requestCallbackClickHandler() {
      const { companyId, opportunityId } = this
      const opportunityCallbackData = { companyId, opportunityId }

      if (this.$config.whitelabel.components.requestCallbackEmail) {
        opportunityCallbackData.email = this.$config.whitelabel.components.requestCallbackEmail
      }

      this.$ma.trackEvent('Request a callback clicked')
      this.trackIfLloydsEvent('RequestCallback')
      return opportunityCallback(opportunityCallbackData).then(res => {
        this.setOpportunityStage('isRequestApp')
      })
    },
    processedToApplicationClickHandler() {
      this.trackIfLloydsEvent('SubmitForSwoopReview')
      this.errorResponse = null
      return this.submitForReview()
        .then(() => {
          this.setOpportunityStage('isUnderReview')
        })
        .catch(e => {
          this.toastError()
        })
    },
    processBarclaysFlow() {
      return Promise.all([this.submitForBarclaysReview(), this.processedToApplicationClickHandler()])
    },
    documentsListChangeHandler() {
      this.loadDocumentsList()
    },
    requiredDocumentsListCheckboxClickHandler(doc) {
      const index = this.documentsListCheckbox.findIndex(d => d.title === doc.title && d.type === doc.type)
      if (index === -1) {
        this.documentsListCheckbox.push(doc)
      } else {
        this.documentsListCheckbox.splice(index, 1)
      }
    },
    toastError() {
      this.$toasted.clear()
      this.$toasted.show('Something went wrong. Please try again later', { ...toastDefaultOptions, type: 'error', icon: 'error' })
    },
    submitForReview() {
      this.submitAnalytics()
      const { opportunityApplicationId } = this
      if (opportunityApplicationId) {
        return setOpportunityApplicationInReview({
          opportunityApplicationId
        })
      } else {
        const { borrowingAmount, annualPercentageRate, monthlyRepaymentAmount, numberOfRepayments, totalRepayableAmount, chancesOfSuccess } = this.opportunity
        return setOpportunityApplication({
          companyId: this.companyId,
          opportunityId: this.opportunityId,
          status: 'ReviewingYourDetails',
          borrowingAmount,
          annualPercentageRate,
          monthlyRepaymentAmount,
          numberOfRepayments,
          totalRepayableAmount,
          chancesOfSuccess
        }).then(res => {
          return this.$router.replace({
            name: 'deal-details',
            params: { opportunityApplicationId: res.data.opportunitiyApplicationId }
          })
        })
      }
    },
    submitForBarclaysReview() {
      return submitBarclaysSoftSearch(this.correlationId).catch(() => {
        this.$toasted.show('There was an error processing your request', { ...toastDefaultOptions, type: 'error', icon: 'error' })
      })
    },
    // Submit For Swoop Review Root
    submitForSwoopReviewClickHandler() {
      this.$ma.trackEvent(amplitudeEvents.dashboard.START_SWOOP_REVIEW)
      this.trackIfLloydsEvent('StartSwoopReview')
      this.appRedirectErrorResponse = null
      if (this.correlationId) {
        submitRBSSoftSearch(this.correlationId)
      }

      if (this.eligibleStatus && this.opportunity.providerName === 'RBS' && this.opportunity.subcategory === 'TermLoan') {
        return this.appRedirect('eligibleTermLoanRbsApp')
      } else if (this.eligibleStatus && this.opportunity.providerName === 'NatWest' && this.opportunity.subcategory === 'TermLoan') {
        return this.appRedirect('eligibleTermLoanNatWestApp')
      } else if (this.eligibleStatus && this.opportunity.providerName === 'Ulster Bank' && this.opportunity.subcategory === 'TermLoan') {
        return this.appRedirect('eligibleTermLoanUlsterBankApp')
      } else if (this.eligibleStatus && this.opportunity.providerName === 'HSBC' && this.opportunity.subcategory === 'TermLoan') {
        return this.appRedirect('eligibleTermLoanHsbcApp')
      } else if (this.eligibleStatus && this.opportunity.providerName === 'HSBC' && this.opportunity.subcategory === 'Overdrafts') {
        return this.appRedirect('eligibleOverdraftsHsbcApp')
      } else if (this.eligibleStatus && this.opportunity.providerName === 'Barclays' && this.opportunity.subcategory === 'TermLoan') {
        this.submitForBarclaysCounterAmplitude()
        return this.processBarclaysFlow()
      } else if (!this.eligibleStatus && !this.correlationId) {
        return this.processedToApplicationClickHandler()
      }
    },
    submitForBarclaysCounterAmplitude() {
      this.$ma.addItem({
        'Counter Submit to Barclays': 1
      })
    },
    readOnlyProductContinueToClickHandler(event) {
      this.trackReadOnlyProductRedirect()
      this.trackIfLloydsEvent('ContinueToProvider')
      const url = event.target.href
      const windowObject = window.open(url, '_blank')
      windowObject.opener = null
    },
    innovationGrantInterestRegistration() {
      registerInterestForInnovationGrant(this.companyId)
        .then(() => {
          this.trackIfLloydsEvent('RegisterInterest')

          store.dispatch(notificationRoutine.TRIGGER, {
            title: 'Registered your interest!',
            type: 'success',
            options: {
              ...toastDefaultOptions,
              duration: 10000
            },
            text:
              'Thank you for registering your interest in this grant. Our team will review your eligibility and if we feel you are a fit for this grant or another innovation grant opportunity, we will be in touch.'
          })
          this.innovationGrantEnabled = false
        })
        .catch(() => {
          store.dispatch(notificationRoutine.TRIGGER, {
            title: 'Something went wrong',
            type: 'error',
            options: {
              ...toastDefaultOptions,
              duration: 10000
            },
            text: 'There was an error registering your interest. Please try again later.'
          })
        })
    },
    trackIfLloydsEvent(eventName) {
      if (getWhitelabelNameFromSubdomain() !== 'lloydsbank') return
      this.$ma.trackEvent({
        eventType: amplitudeEvents.lloydsSMEGrants.MATCHES_CLICK_THROUGH,
        eventProperties: {
          click_name: eventName,
          opportunityId: this.opportunity.opportunityId,
          productType: this.opportunity.type,
          productName: this.opportunity.productName,
          productVendor: this.opportunity.providerName
        }
      })
    },
    appRedirect(modalName) {
      this.appRedirectErrorResponse = null
      return this.submitForReview()
        .then(() => {
          let url
          switch (modalName) {
            case 'eligibleTermLoanHsbcApp':
              url = eligibleTermLoanHsbcAppUrl
              break
            case 'eligibleOverdraftsHsbcApp':
              url = eligibleOverdraftsHsbcAppUrl
              break
          }
          if (url) {
            const windowObject = window.open(url, '_blank')
            windowObject.opener = null
          }
          this.setOpportunityStage('isUnderReview')
        })
        .catch(e => {
          this.appRedirectErrorResponse = e.response
        })
    },
    trackReadOnlyProductRedirect() {
      this.$ma.trackEvent({
        eventType: 'CTA grant conversion',
        eventProperties: {
          category: 'CTA grant conversion',
          action: 'click',
          label: this.opportunity.providerName
        }
      })
      this.trackIfLloydsEvent('ContinueToProvider')
    },
    trackLiveOfferFillInfo(info) {
      this.$ma.trackEvent({
        eventType: 'Live Offer Information Fill clicked',
        eventProperties: {
          Information: info,
          'Product ID': this.opportunityId,
          'Product Name': this.opportunity.productName,
          'Product Vendor': this.opportunity.providerName
        }
      })
      this.trackIfLloydsEvent('LiveOfferInformationFillClicked')
    },
    trackLiveOffersClickCheckEligibility() {
      this.$ma.trackEvent({
        eventType: 'Live Offer Check Eligibility clicked',
        eventProperties: {
          'Product ID': this.opportunityId,
          'Product Name': this.opportunity.productName,
          'Product Vendor': this.opportunity.providerName
        }
      })
      this.trackIfLloydsEvent('LiveOfferCheckEligibility')
    },
    submitAnalytics() {
      /** Amplitude Analytics */
      this.$ma.addItem({
        'Fund App Total Counter': 1
      })
      this.$ma.setUserProperties({
        [`Fund App ${this.opportunity?.type} Submitted`]: true
      })
      this.$ma.trackEvent({
        eventType: amplitudeEvents.fundingCheckout.APPLY_FOR_PRODUCT,
        eventProperties: {
          'Product ID': this.opportunityId,
          'Product Type': this.opportunity.type,
          'Product Name': this.opportunity.productName,
          'Product Vendor': this.opportunity.providerName
        }
      })
      this.trackIfLloydsEvent('ApplyForProduct')
    },
    checkEligibilityRes(res) {
      return !res || res?.data?.status === '' || res?.data?.status === null
    },
    setBarclaysOpportunity(barclaysData) {
      this.opportunity = {
        ...this.opportunity,
        borrowingAmount: barclaysData.BorrowingAmount,
        annualPercentageRate: barclaysData.AnnualPercentageRate,
        monthlyRepaymentAmount: barclaysData.MonthlyRepaymentAmount,
        numberOfRepayments: barclaysData.NumberOfRepayments,
        totalRepayableAmount: barclaysData.TotalRepayableAmount,
        chancesOfSuccess: barclaysData.ChancesOfSuccess,
        quoteReferenceId: barclaysData.QuoteReferenceId
      }
    },
    async doSoftSearch(postSoftSearchFn, getSoftSearchFn) {
      try {
        const postResponse = await postSoftSearchFn(this.companyId)
        if (!postResponse.data) return
        this.correlationId = postResponse.data

        const getResponse = await makeRequestTillPassCondition(getSoftSearchFn, [this.companyId, this.correlationId], this.checkEligibilityRes, 2000, 15)
        this.eligibleStatus = getResponse.data.status || 'Not Eligible'
        this.currentApplicationId = getResponse.data?.additionalData?.ApplicationID

        if (this.eligibleStatus === 'Not Eligible') {
          return this.setOpportunityStage('isNotEligible')
        }

        if (this.opportunity.providerName === 'Barclays') {
          this.setBarclaysOpportunity(getResponse.data?.additionalData)
        } else {
          const response = await getFundingApplication(this.fundingApplicationId)
          this.currentTotalFundingRequired = response.data.totalFundingRequired.toFixed(2)
        }
        this.setOpportunityStage('isSubmitEligible')
      } catch (error) {
        this.toastError()
      }
    },
    checkEligibility() {
      if (this.isLiveOffers) {
        this.trackLiveOffersClickCheckEligibility()
      }
      this.setOpportunityStage('isCheckingEligibility')
      if (['RBS', 'NatWest', 'Ulster Bank'].includes(this.opportunity.providerName) && this.opportunity.subcategory === 'TermLoan' && this.isRBSEligibilityEnabled) {
        return this.doSoftSearch(postRBSSoftSearch, getRBSSoftSearch)
      }
      if (this.opportunity.providerName === 'HSBC' && (this.opportunity.subcategory === 'TermLoan' || this.opportunity.subcategory === 'Overdrafts')) {
        return this.doSoftSearch(postHSBCSoftSearch, getHSBCSoftSearch)
      }
      if (this.opportunity.providerName === 'Barclays' && this.opportunity.subcategory === 'TermLoan') {
        return this.doSoftSearch(postBarclaysSoftSearch, getBarclaysSoftSearch)
      }
    },
    async initOpportunityStage() {
      if (this.isLiveOffers) {
        const requests = [this.loadCompanyOwners(), this.loadFinancialInformation()]
        if (this.opportunity.providerName === 'Barclays' && this.opportunity.subcategory === 'TermLoan') {
          requests.push(this.loadAdditionalFinancialInformation())
        }
        await Promise.all(requests)
        this.setOpportunityStage('isStartCompanyOwners')
      } else if (this.opportunity?.type === 'Loan' || this.opportunity?.type === 'LiveOffer') {
        await Promise.all([this.loadCompany(), this.loadDocumentsList()])
        if (this.productStatus === 'InformationRequested' || this.opportunity.status === 'InformationRequested') {
          this.setOpportunityStage('isBookmarkedApp')
        } else if (this.productStatus === 'ReviewingYourDetails') {
          this.setOpportunityStage('isUnderReview')
        } else {
          this.setOpportunityStage('isStartApp')
        }
      } else if (this.opportunity?.type === 'Grant') {
        await Promise.all([this.loadCompany(), this.loadDocumentsList()])
        const documentCategory = 'isBusinessPlan'
        this.setOpportunityStage(documentCategory)
      }
    },
    async init() {
      // check if there is opportunityApplicationId
      if (this.opportunityApplicationId) {
        await this.loadOpportunityApplication()
        await this.checkEligibility()
      } else {
        await this.loadOpportunity()
      }
      await this.getSubcategoryName()
      const res = await getProductStatus(this.companyId, this.opportunityId)
      this.productStatus = res?.data?.status

      if (this.opportunity.isProductWithoutSubmitFlow === false) {
        this.setOpportunityStage('isProductWithoutSubmitFlow')
      } else if (this.opportunity.status === 'ReviewingYourDetails') {
        this.setOpportunityStage('isUnderReview')
      } else {
        await this.initOpportunityStage()
      }
    },
    onDontHaveDocsBtnClick () {
      this.$modal.show('haveNoDocumentsModal')
      this.$ma.trackEvent({
        eventType: amplitudeEvents.opportunity.MISSING_DOCUMENTS_MODAL_OPENED,
        eventProperties: {
          productType: this.opportunity.type,
          productSubType: this.opportunity.subcategory,
          numberRequiredDocs: this.requiredDocumentsList.length
        }
      })
    }
  },
  created() {
    this.init()
  },
  unmounted() {
    window.removeEventListener('resize', this.width)
  }
}
</script>

<style lang="scss" src="./OpportunityView.scss" scoped></style>
