<template>
  <div class="tile-container">
    <div class="tile">
      <div class="box-wrapper">
        <div class="detail-card">
          <div class="provider-details">
            <img class="provider-logo" :src="logoUri" :alt="opportunity.type + ' logo'" v-if="opportunity && opportunity.logoUri" />
            <div class="provider">
              <div class="label">Provider</div>
              <div class="providerName">{{ opportunity.providerName }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-wrapper">
        <div class="detail-card">
          <div class="title">Rates from</div>
          <div class="value" v-if="'aprMin' in opportunity && opportunity.aprMin !== null">{{ opportunity.aprMin | numberSeparator }}%</div>
          <div class="value" v-else>0%</div>
        </div>
      </div>
      <div class="box-wrapper">
        <div class="detail-card">
          <div class="title">Term</div>
          <div class="value" v-if="'speed' in opportunity && opportunity.speed !== null">{{ loanTermMaxDic[opportunity.minTerm] }}-{{ loanTermMaxDic[opportunity.maxTerm] }}</div>
          <div class="value" v-else>N/A</div>
        </div>
      </div>
      <div class="box-wrapper">
        <div class="detail-card">
          <div class="title">Decision time</div>
          <div class="value" v-if="'speed' in opportunity && opportunity.speed !== null">{{ opportunitySpeedDic[opportunity.speed] }}</div>
          <div class="value" v-else>N/A</div>
        </div>
      </div>
      <div class="box-wrapper">
        <div class="detail-card">
          <div class="title">Amount</div>
          <div class="amount">
            <span class="value" v-if="'minSize' in opportunity && opportunity.minSize !== null"><currency-symbol />{{ opportunity.minSize | commarize }} - </span>
            <span class="value" v-if="'maxSize' in opportunity && opportunity.maxSize !== null"><currency-symbol />{{ opportunity.maxSize | commarize }}</span>
            <div class="value" v-else>N/A</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CurrencySymbol from '@/components/CurrencySymbol'

export default {
  name: 'LoanOpportuniyTile',
  components: {
    CurrencySymbol
  },
  props: {
    opportunity: {
      type: Object,
      required: true
    },
    opportunitySpeedDic: {
      type: Object,
      required: true
    },
    logoUri: {
      type: String,
      required: true
    },
    loanTermMaxDic: {
      type: Object,
      required: true
    }
  }
}
</script>
