<template>
  <div>
    <header class="product-header m-b-20">
      <div class="ui stackable container">
        <RoundImage>
          <img class="logo2" :src="logoUri" :alt="opportunity.type + ' logo'" v-if="opportunity && opportunity.logoUri" />
        </RoundImage>
        <div class="header-info m-l-25">
          <h2 class="m-b-10">{{ opportunity.productName }}</h2>
          <h3 class="opportunity-type h6 m-b-5">
            {{ displayedOpportunityType }}
            <template v-if="currentOpportunitySubCategory">-</template>
            {{ currentOpportunitySubCategory }}
          </h3>
        </div>
      </div>
    </header>
    <div class="product-details-nav m-b-25 p-t-20">
      <NeedHelp :contactInformation="contactInformation" />
    </div>
    <div class="tile-container">
      <div class="tile">
        <div class="box-wrapper">
          <div class="detail-card">
            <div class="title">Grant Range</div>
            <div class="value">
              <span class="value" v-if="opportunity.minSize > 1 && opportunity.maxSize">{{ opportunity.minSize | commarize | currency }}-{{ opportunity.maxSize | commarize | currency }}</span>
              <span class="value" v-else-if="opportunity.maxSize">{{ opportunity.maxSize | commarize | currency }}</span>
              <div class="value" v-else>N/A</div>
            </div>
          </div>
        </div>
        <div class="box-wrapper">
          <div class="detail-card">
            <div class="title">Closing Date</div>
            <div class="value" v-if="opportunity.closingDate">{{ opportunity.closingDate | dateFormat }}</div>
            <div class="value" v-else>N/A</div>
          </div>
        </div>
        <div class="box-wrapper">
          <div class="detail-card">
            <div class="title">Sector Preference</div>
            <div class="value" v-if="opportunity.isSpecificBusinessSector === null">N/A</div>
            <div class="value" v-else>{{ opportunity.isSpecificBusinessSector ? 'Yes' : 'No' }}</div>
          </div>
        </div>
        <div class="box-wrapper">
          <div class="detail-card">
            <div class="title">Geographic Preferences</div>
            <div class="value" v-if="opportunity.isSpecificRegion === null">N/A</div>
            <div class="value" v-else>{{ opportunity.isSpecificRegion ? 'Yes' : 'No' }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NeedHelp from '@/components/NeedHelp'
import RoundImage from '@/components/RoundImage'

export default {
  name: 'GrantOpportuniyTile',
  components: {
    RoundImage,
    NeedHelp
  },
  props: {
    opportunity: {
      type: Object,
      required: true
    },
    displayedOpportunityType: {
      type: String,
      required: true
    },
    currentOpportunitySubCategory: {
      type: String,
      required: true
    },
    contactInformation: {
      type: String
    },
    logoUri: {
      type: String
    }
  }
}
</script>
