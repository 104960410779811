import { swoopAPI, recommendationApi } from '@/api'

/* Recommendation API endpoint URL creators */
const createPostUrl = (companyId, liveOfferProductId) => `/eligibility/company/${companyId}/softsearch/${liveOfferProductId}`
const createGetUrl = (companyId, correlationId) => `/eligibility/company/${companyId}/correlation/${correlationId}/v2`

/* RBS */
const rbsLiveOfferProductId = 'ef840684-0f31-4f53-9e3a-b77db98e07f4'
export const postRBSSoftSearch = companyId => recommendationApi.post(createPostUrl(companyId, rbsLiveOfferProductId))
export const getRBSSoftSearch = (companyId, correlationId) => recommendationApi.get(createGetUrl(companyId, correlationId))
export const submitRBSSoftSearch = correlationId => swoopAPI.post(`/api/v1/rbsSoftSearch/submit?correlationId=${correlationId}`)

/* HSBC */
const hsbcLiveOfferProductId = 'c20d3a36-ba15-479c-9dd8-3926da5da23d'
export const postHSBCSoftSearch = companyId => recommendationApi.post(createPostUrl(companyId, hsbcLiveOfferProductId))
export const getHSBCSoftSearch = (companyId, correlationId) => recommendationApi.get(createGetUrl(companyId, correlationId))

/* Barclays */
const barclaysLiveOfferProductId = '2a7c7bd7-6337-4388-bfd4-fb1de8870b44'
export const postBarclaysSoftSearch = companyId => recommendationApi.post(createPostUrl(companyId, barclaysLiveOfferProductId))
export const getBarclaysSoftSearch = (companyId, correlationId) => recommendationApi.get(createGetUrl(companyId, correlationId))
export const submitBarclaysSoftSearch = correlationId => swoopAPI.post('/api/v1/barclaysSoftSearch/submit', null, { params: { correlationId } })
