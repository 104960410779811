<template>
  <div>
    <div v-if="updatedRequiredDocumentsList && updatedRequiredDocumentsList.length">
      <div v-for="doc in updatedRequiredDocumentsList" :key="doc.key">
        <!-- if document is checkbox -->
        <Checkbox
          v-if="doc.isCheckBox"
          class="list-item"
          :name="doc.type + doc.title"
          :label="doc.label"
          :value="doc.isDocumentUploaded"
          @input="checked => $emit('click:checkbox', { ...doc, checked })"
        />

        <!-- if document needs to be uploaded -->
        <div v-else>
          <!-- document has uploaded -->
          <div v-if="doc.isDocumentUploaded">
            <div class="label">{{ doc.label }}</div>
            <div class="uploaded-documents">
              <i class="material-icons">description</i>
              <div class="width">
                <a :class="{ link: true, disabled: allowMultipleFiles === false && doc.isDocumentUploaded === true }" @click.prevent="uploadDocumentClickHandler(doc)">
                  <div v-if="doc.uploadedDocumentNames && doc.uploadedDocumentNames.length" class="file-names">
                    <span v-for="fileName in doc.uploadedDocumentNames" :key="fileName">{{ fileName }}</span>
                  </div>
                </a>
                <div class="details" v-if="!isDocumentUploading">({{ doc.date | dateFormatWithHours}} , {{ doc.size | fileSize }})</div>
              </div>
            </div>
          </div>
          <div v-else-if="currentDocument && currentDocument.label === doc.label && isDocumentUploading">
            <div class="label">{{ doc.label }}</div>
            <div class="uploaded-documents">
              <i class="material-icons">description</i>
              <div class="width">
                <a :class="{ link: true, disabled: allowMultipleFiles === false && doc.isDocumentUploaded === true }" @click.prevent="uploadDocumentClickHandler(doc)">
                  <div v-if="doc.uploadedDocumentNames && doc.uploadedDocumentNames.length" class="file-names">
                    <span v-for="fileName in doc.uploadedDocumentNames" :key="fileName">{{ fileName }}</span>
                  </div>
                </a>
                <ProgressBar :value="value" :max="max" />
              </div>
            </div>
          </div>
          <!-- document has not been uploaded -->
          <a
            v-else
            class="document-details-wrapper"
            :class="{ disabled: allowMultipleFiles === false && doc.isDocumentUploaded === true }"
            @click.prevent="uploadDocumentClickHandler(doc)"
          >
            <div>{{ doc.label }}</div>
            <div v-if="doc.uploadedDocumentNames && doc.uploadedDocumentNames.length" class="file-names">
              <span v-for="fileName in doc.uploadedDocumentNames" :key="fileName">{{ fileName }}</span>
            </div>
            <i class="material-icons">cloud_upload</i>
          </a>
        </div>
      </div>
    </div>
    <div v-else class="ui small message">No required documents</div>

    <div v-if="currentDocument">
      <div class="ui header dark text">Upload your {{ currentDocument.label }}</div>
      <div class="ui subtitle">To upload, make sure it's a PDF smaller than 20MB</div>
      <template>
        <DropFile class="ui m-t-1" :rules="`size:20480|ext:${whiteListDocumentExtensions}`" @change="dropFileChangeHandler" ref="dropFile" />
        <div class="text-danger ui m-b-1" v-if="documentUploadErrorResponse && documentUploadErrorResponse.data">{{ documentUploadErrorResponse.data.message }}</div>
        <DocumentsList class="documents-list" :filesList="filteredFilesList" @change="fileListChangeHandler" />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import DocumentsList from '@/components/DocumentsList'
import DropFile from '@/components/forms/DropFile'
import Checkbox from '@/components/forms/Checkbox'
import { sendDocument } from '@/api/document'
import { whiteListDocumentExtensions } from '@/utils/constants'
import { sendBasicEventToAmplitude, journeyStepsButtonAmplitudeEvent } from '@/utils/utils'
import ProgressBar from '@/components/ProgressBar'

export default {
  name: 'RequiredDocuments',
  components: {
    DocumentsList,
    DropFile,
    Checkbox,
    ProgressBar
  },
  props: {
    documentsList: {
      type: Array
    },
    requiredDocumentsList: {
      type: Array
    },
    allowMultipleFiles: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      whiteListDocumentExtensions,
      currentDocument: null,
      isDocumentUploading: false,
      documentUploadErrorResponse: null,
      sendBasicEventToAmplitude,
      journeyStepsButtonAmplitudeEvent,
      value: null,
      max: null
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID
    }),
    filteredFilesList() {
      if (!this.currentDocument || !this.documentsList) return []
      return this.documentsList.filter(doc => doc.title === this.currentDocument.title && doc.type === this.currentDocument.type)
    },
    updatedRequiredDocumentsList() {
      return this.requiredDocumentsList.map(document => {
        const toFind = this.documentsList?.find(doc => doc.title === document.title && doc.type === document.type)
        if (toFind) {
          document.date = toFind.uploadedDate
          document.size = toFind.documentSize
        }
        return document
      })
    }
  },
  methods: {
    uploadDocumentClickHandler(doc) {
      sendBasicEventToAmplitude(this.$ma, journeyStepsButtonAmplitudeEvent(doc.amplitudeEvent))
      if (this.allowMultipleFiles === false && doc.isDocumentUploaded === true) {
        this.value = 100
        return
      }
      this.currentDocument = doc
    },
    dropFileChangeHandler(fileList) {
      this.isDocumentUploading = true
      this.value = 0
      this.documentUploadErrorResponse = null
      const uDocPromises = fileList.map(doc => {
        const params = {
          companyId: this.companyId,
          type: this.currentDocument.type,
          title: this.currentDocument.title
        }
        const formData = new FormData()
        formData.append('file', doc.file)
        return sendDocument(formData, params)
      })
      return Promise.all(uDocPromises)
        .then(() => {
          this.fileListChangeHandler()
          this.$nextTick(() => {
            this.isDocumentUploading = false
          })
        })
        .catch(e => {
          this.documentUploadErrorResponse = e.response
          this.isDocumentUploading = false
        })
    },
    fileListChangeHandler() {
      this.$emit('change')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_mixins.scss';
@import '@/assets/styles/swoop/_variables.scss';

.document-details-wrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--color-primary-100);
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.label {
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.004em;
  color: var(--color-primary-500);
}

.uploaded-documents {
  margin-bottom: 20px;
  @include flex-display(row, center, flex-start);
  i {
    margin-right: 20px;
    font-size: 30px;
  }
  .width {

    flex: 1;
  }
  .details{
    font-size: 12px;
  }
}
/deep/ a {
  text-decoration: none;
}

/deep/ .file-upload {
  margin-top: 20px;
}

/deep/ .progress-bar {
  height: 6px;
}
/deep/ .progress-bar-value {
  background-color: var(--color-secondary-500);
  width: 400px;
  @media only screen and (max-width: $breakpoint-sm-max) {
    width: 210px;
  }
}
</style>
