<template>
  <div class="progress-bar" role="progressbar">
    <div class="progress-bar-value" :style="style"></div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    value: {
      type: Number,
      validator: prop => Number.isFinite(prop) && prop >= 0,
      default: 0
    },
    max: {
      type: Number,
      validator: prop => Number.isFinite(prop) && prop > 0,
      default: 100
    }
  },
  computed: {
    style() {
      return {
        width: `${Math.min((this.value * 100) / this.max, 100)}%`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/swoop/variables';

.progress-bar {
  height: 4px;
  border-radius: $default-border-radius;
  background: transparent;
}
.progress-bar-value {
  height: 100%;
  border-radius: $default-border-radius;
  background: $color-primary;
  transition: width ease-in-out 0.2s;
}
</style>
