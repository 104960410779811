<template>
  <div>
    <modal name="haveNoDocumentsModal" height="auto" :width="600" adaptive scrollable class="modal-container">
      <h3><i class="material-icons icon">description</i>You have missing documents</h3>
      <div class="description">We know you might not have them to hand right now, so we can send you an email reminder to come back and upload them when you’re ready.</div>
      <div class="flex">
        <div class="close-modal-button" @click="onNoClick">
          <button class="btn btn-sm btn-white">No thanks</button>
        </div>
        <div class="open-modal-button" @click="onYesClick">
          <button class="btn btn-sm btn-primary">Yes please!</button>
        </div>
      </div>
      <div @click="dontShow()">
      <Checkbox  v-model="dismiss" name="dismiss" label="Don't show me this again." /></div>
    </modal>

    <modal name="goodToKnowModal" height="auto" :width="600" adaptive scrollable class="modal-container">
      <h3><i class="material-icons icon">description</i>Good to know!</h3>
      <div class="description">We've haven’t actually built this feature yet, but we really appreciate you letting us know it would be useful!</div>
      <div class="close-modal-button" @click="$modal.hide('goodToKnowModal', 'haveNoDocumentsModal')">
        <button @click="closeAllModals()" class="btn btn-sm btn-primary">Got it!</button>
      </div>
    </modal>
  </div>
</template>

<script>
import Checkbox from '@/components/forms/Checkbox'
import { amplitudeEvents } from '@/utils/constants'

export default {
  name: 'haveNoDocumentsModal',
  components: {
    Checkbox
  },
  props: {
    dismiss: {
      type: Boolean
    }
  },
  methods: {
    onNoClick() {
      this.$modal.hide('haveNoDocumentsModal')
      this.$ma.trackEvent({
        eventType: amplitudeEvents.opportunity.MISSING_DOCUMENTS_MODAL_ANSWERED,
        eventProperties: {
          wantReminder: 'NO'
        }
      })
    },
    onYesClick() {
      this.$modal.show('goodToKnowModal')
      this.$ma.trackEvent({
        eventType: amplitudeEvents.opportunity.MISSING_DOCUMENTS_MODAL_ANSWERED,
        eventProperties: {
          wantReminder: 'YES'
        }
      })
    },
    closeAllModals() {
      this.$modal.hide('haveNoDocumentsModal')
    },
    dontShow() {
      localStorage.setItem('dontShowModal', true)
    }
  }
}
</script>
<style scoped lang="scss">
.modal-container {
  /deep/ .checkbox-label {
    vertical-align: bottom;
  }
}
</style>
